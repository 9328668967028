import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet'

const CV = () => {
  const siteTitle = 'Onodera Ryo | cv'

  return(
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
      </Helmet>
      <div id="main">
        <h1 class="name2">小野寺 亮 / Ryo Onodera</h1>
        <p class="own_desc">1993年、福島県生まれ。東京在住。カタログ、広告、アーティスト写真など幅広く活動。<br></br>
          映画監督として映画、広告、MVなども製作している。主な個展に"0.1cm"(Tokyo,2017)、"ZOTOKA(Tokyo,2017)"など。
        </p>
        <p class="own_desc">
          Born in Fukushima City, 1993. Currently resides in Tokyo.<br></br>
          Works include a wide vatiety of genres, from fashion magazines, to advertisement, to catalogues, to portraits and publicity shots. Also, Produces film, advertisements and music video as a video director.<br></br>
          Past exhibitions include "o.1cm"(TOkyo,2017), and "ZÔTÔKA"(Tokyo,2017).
        </p>
        <p class="own_desc">
          Solo Exhibition<br></br>
          2017 "0.1cm" (Tokyo)<br></br>
          2017 "ZOTOKA" (Tokyo)
        </p>
        <p class="own_desc">
          Collaboratuve Exhibition<br></br>
          2016 "Yosomono" (Tokyo)<br></br>
          2017 "Nobody Knows" (Tokyo)<br></br>
          2017 "The work of actors and painters" (Tokyo)
        </p>
      </div>
    </Layout>
  )
}

export default CV